<template>
    <div class='container'>

        <div class="box" style="padding: 20px;">

            <h3>你好，【{{ name }}】</h3>

            <div class="grade">
                <div>您的身体健康综合得分是：</div>
                <div class="bignumber">{{ data.score.total }}</div>
                <div style="font-weight: 700">/ 100</div>
            </div>

            <div id="main" style="width: 100%;height:280px;"></div>
        </div>

        <div class="box box1" style="padding: 25px 15px">

            <div class="title1">
                <div class="title1-l"></div>
                <div class="title1-r">需要注意的潜在问题</div>
            </div>

            <div class="titleconst" v-for="(item,key, index) in score" :key="index"
                 v-show="data.suggest[key] && key != 'eat'">
                <div class="titleconst-t">
                    <div class="titleimg">
                        <img :src="`image/${key}.png`" alt="">
                    </div>
                    <div>{{ item }}问题</div>
                </div>
                <div class="titletext">
                    <span v-html="data.suggest[key] && data.suggest[key].slice(0,100)+'...'"></span>

                    <span class="open open-avtive" @click="isEllipsis($event,data.suggest[key])">展开</span>
                </div>

            </div>

        </div>


        <div class="box box1 box2">
            <div class="title1">
                <div class="title1-l"></div>
                <div class="title1-r ">
                    <div class="test">您的饮食习惯综合评分：</div>
                    <div class="test mesotype">{{ data.score.eat }}</div>
                    <div class="test">/100</div>
                </div>
            </div>

            <div class="box2-base" v-html="data.suggest.eat">

            </div>
        </div>


        <div class="box4 flex-between">
            <div class="box4-hashagei">
                <div>清楚的了解你的身体</div>
                <div>我们来一起改善吧</div>
            </div>
            <div class="btn" @click="href">
                联系我的营养顾问
            </div>
            <!--<van-button color="#FE4B12" @click="href">联系我的营养顾问</van-button>-->
        </div>


    </div>
</template>

<script>
    let echarts = require('echarts/lib/echarts')
    require('echarts/lib/chart/radar')
    export default {
        // Vue 实例的数据对象
        data() {
            return {
                data: {},
                name: '',
                score: {
                    hair: '头发',
                    sleep: '睡眠',
                    brain: '脑力',
                    pressure: '压力',
                    skin: '皮肤',
                    bmi: '体重指数',
                    eye: '眼睛',
                    heart: '心脏健康',
                    immunity: '免疫力',
                    energy: '能量',
                    digest: '消化',
                    bone: '骨骼',
                    eat: '饮食健康',

                }
            }
        },
        created() {
            let answer = JSON.parse(window.sessionStorage.getItem("answer"))
            let name = window.localStorage.getItem("name")

            this.name = name
            if (answer) {
                this.data = answer
            } else {

            }
        },
        mounted() {
            this.chart()

        },
        methods: {
            chart() {
                var myChart = echarts.init(document.getElementById('main'))

                let {score} = this.data
                //初始化图标
                let indicator = []
                let values = []

                for (let name in this.score) {
                    let obj = {}
                    obj.text = this.score[name]

                    obj.max = 100
                    indicator.push(obj)
                    values.push(score[name])
                }

                // 指定图表的配置项和数据
                var option = {
                    color: ['rgba(109, 0, 175, 0.3)'],
                    radar: [
                        {
                            indicator: indicator,
                            center: ['50%', '50%'],
                            radius: 83,
                            startAngle: 0,
                            splitNumber: 3,
                            shape: 'circle',
                            name: {
                                textStyle: {
                                    color: '#000000',
                                    fontSize: 14,

                                }
                            },
                            splitArea: {
                                areaStyle: {
                                    color: ['#F7F7F7'],
                                }
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#F3F3F4',
                                }
                            },
                            splitLine: {
                                lineStyle: {
                                    color: 'rgba(109, 0, 175, 0.2)'
                                },
                            }
                        }
                    ],
                    series: [
                        {
                            name: '雷达图',
                            type: 'radar',
                            symbolSize: 0, //设置各个拐点的大小
                            areaStyle: {},
                            data: [
                                {
                                    value: values
                                }
                            ]
                        }
                    ]
                }
                myChart.setOption(option)
            },
            href() {
                this.$router.push({
                    path: '/my/consult'
                })
            },
            isEllipsis(e, html) {
                let obj = e.currentTarget.previousElementSibling
                let btn = e.currentTarget
                //判断未展开
                if (obj.innerHTML.length < html.length) {
                    btn.classList.remove('open-avtive')
                    btn.innerText = '收起'
                    obj.innerHTML = html
                } else {
                    btn.classList.add('open-avtive')
                    btn.innerText = '展开'
                    obj.innerHTML = html.slice(0, 100)+'...'
                }

            }
        }
    }
</script>

<style lang='less' scoped>
    .container {
        background: #f3f3f4;
        min-height: 100%;
        font-size: 14px;
        color: #666666;
        padding: 15px 10px;
        padding-bottom: 95px;

        .open {
            font-size: 14px;
            position: absolute;
            white-space: nowrap;
            font-weight: 700;
            right: 8px;
            bottom: 5px;
        }

        .open-avtive {
            color: #9E3DF6;

        }
        .box {
            background-color: #ffffff;
            box-sizing: border-box;
            margin-bottom: 15px;
            border-radius: 10px;

            h3 {
                display: inline-block;
                font-size: 21px;
                color: #292c2f;

                border-bottom: 4px solid #292c2f;
                padding-bottom: 3px;
            }

            .grade {
                display: flex;
                color: #3f3f3f;
                white-space: nowrap;
                align-items: flex-end;
                font-size: 17px;

                .bignumber {
                    font-size: 40px;
                    transform: translateY(6px);
                    color: #6f04b0;
                    font-weight: 700;
                }
            }
        }

        .box1 {
            .title1 {
                display: flex;
                align-items: center;

                .title1-l {
                    width: 6px;
                    height: 27px;
                    background: #6b00af;
                    border-radius: 10px;
                }

                .title1-r {
                    padding-left: 9px;
                    color: #292c2f;
                    font-size: 20px;
                    display: flex;
                    font-weight: 700;
                }
            }

            .titleconst {
                margin-top: 17px;
                background-color: #F3F3F4;
                position: relative;
                border-radius: 10px;

                .titleconst-t {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    color: #000000;
                    font-weight: 700;
                    padding: 7px 0px;
                    padding-left: 14px;
                    .titleimg {
                        width: 34px;
                        height: 34px;
                        margin-right: 8px;
                    }
                }

                .titletext {
                    line-height: 22px;
                    font-size: 12px;
                    color: #666666;
                    background-color: #F7F7F7;
                    padding: 20px 20px;
                    letter-spacing: 0.5px;
                    /deep/ img {
                        padding-bottom: 10px;
                    }
                }
            }
        }

        .box2 {
            padding: 25px 20px;

            .test {
                display: flex;
                align-items: center;
                font-weight: 700;
                white-space: nowrap;
            }

            .mesotype {
                //font-size: 24px;
                //font-weight: bold;
                //margin-top: -5px;
            }

            .box2-base {
                padding: 20px 5px 0px 15px;
                line-height: 24px;
                font-size: 12px;
                color: #292C2F;
            }
        }

        .box4 {
            width: 100%;
            background: white;
            height: 75px;
            padding: 19px 15px 19px 25px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            position: fixed;
            left: 0;
            bottom: 0;
            border-top: 1px solid #CFCFCF;

            .box4-hashagei {
                font-size: 16px;

                line-height: 22px;
                white-space: nowrap;
                color: #292C2F;
            }
            .btn{
                background-color: #FE4B12;
                color: white;
                border-radius: 5px;
                padding: 13px 16px;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
    }
</style>
